.navbar-container {
  user-select: none;
  width: auto;
  background-color: #ffffff;
  margin: 0;
  -webkit-box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.67);
  box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.67);
}

.logo-navbar {
  user-select: none;
  padding-top: 0.3rem;
  padding-left: 2rem;
  margin-bottom: 0.5rem;
  height: 3rem;
}

.contenedor-link {
  user-select: none;
  padding-top: 1rem;
  user-select: none;
  padding-left: 1rem;
  padding-right: 1rem;
  font-family: "Roboto", sans-serif;
  font-size: 1.5rem;
  color: black;
}

.link-navbar {
  user-select: none;
  padding-left: 1rem;
  padding-right: 1rem;
  font-family: "Roboto", sans-serif;
  font-size: 1.5rem;
  color: black;
}

.navbar-div {
  user-select: none;
  display: "flex";
  width: 100vw;
  align-items: "center";
  background-color: #ffffff;
}
