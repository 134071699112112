.titulo-servicios {
  font-family: "Roboto", sans-serif;
  font-size: 3.5rem;
  font-weight: bold;
  color: #1e1e1e;
  margin-top: 70px;
  margin-bottom: 50px;
  user-select: none;
}

.div-banner-servicios-mobile{
  display: none;
}

/* config pil-izquierda */
.pil-ev-neuro {
  padding: 1%;
  margin-top: 5vh;
  margin-right: 35rem;
  margin-left: 50px;
  transition: 0.3s;
  background-color: #b376f1;
  border-radius: 138px;
  cursor: pointer;
}

.pil-ev-neuro:hover {
  padding: 1%;
  margin-top: 5vh;
  margin-right: 35rem;
  margin-left: 50px;
  transition: 0.3s;
  background-color: #8557b3;
  border-radius: 138px;
  cursor: pointer;
}

.placeholder {
  width: 220px;
  height: 220px;
  border-radius: 100%;
  cursor: pointer;
  user-select: none;
  object-fit: cover;
}

.tit-pil {
  user-select: none;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 3.5rem;
  cursor: pointer;
  color: white;
}

.separator {
  height: 2rem;
  width: 100%;
}

/* config pil derecha */
.pil-re-neuro {
  padding: 2%;
  margin-top: 5vh;
  margin-left: 35rem;
  margin-right: 50px;
  transition: 0.3s;
  background-color: #3d9cf3;
  border-radius: 138px;
  cursor: pointer;
}

.pil-re-neuro:hover {
  padding: 2%;
  margin-top: 5vh;
  margin-left: 35rem;
  margin-right: 50px;
  transition: 0.3s;
  background-color: #2d72b3;
  border-radius: 138px;
  cursor: pointer;
}

.tit-pil-re {
  user-select: none;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 3.5rem;
  cursor: pointer;
  justify-content: right;
  text-align: right;
  color: white;
}

/* background */
.circle-background-sv {
  user-select: none;
  position: absolute;
  z-index: -1;
  height: 96.46302250803859vh;
  width: 46.875vw;
  background: #feeeac;
  background: -moz-linear-gradient(top, #feeeac 0%, #c0e6ce 50%, #86dfee 100%);
  background: -webkit-linear-gradient(
    top,
    #feeeac 0%,
    #c0e6ce 50%,
    #86dfee 100%
  );
  background: linear-gradient(to bottom, #feeeac 0%, #c0e6ce 50%, #86dfee 100%);
  border-radius: 50%;
  margin-right: 50%;
}

.rectangle-background-sv {
  user-select: none;
  position: absolute;
  z-index: -1;
  height: 96.46302250803859vh;
  width: 25.625vw;
  background: #feeeac;
  background: -moz-linear-gradient(top, #feeeac 0%, #c0e6ce 50%, #86dfee 100%);
  background: -webkit-linear-gradient(
    top,
    #feeeac 0%,
    #c0e6ce 50%,
    #86dfee 100%
  );
  background: linear-gradient(to bottom, #feeeac 0%, #c0e6ce 50%, #86dfee 100%);
  margin-right: 74%;
}

:where(.css-dev-only-do-not-override-15rg2km).ant-modal .ant-modal-content {
  background-color: #b376f1 !important;
}

:where(.css-dev-only-do-not-override-15rg2km).ant-modal .ant-modal-title {
  background-color: #b376f1 !important;
}

.swal-text {
  font-family: "Roboto", sans-serif;
  font-size: 1.5rem;
  text-align: justify;
  color: white;
  font-weight: bold;
  padding: 2%;
}

.swal-modal {
  margin: auto;
  width: 80%;
  height: auto;
}

.swal-button {
  color: black;
  font-weight: bold;
  font-size: 1.2rem;
}

.swal-pil-re-neuro {
  background-color: #2d72b3 !important;
  border-radius: 50px;
  color: white !important;
  font-weight: bold !important;
}

.swal-pil-ev-neuro {
  background-color: #8557b3 !important;
  border-radius: 50px;
  color: white !important;
  font-weight: bold !important;
}

.swal-title {
  font-family: "Roboto", sans-serif;
  font-size: 2rem;
  text-align: justify;
  color: white;
}

@media (max-width: 1024px) {
  .tit-pil {
    user-select: none;
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    font-size: 3rem;
    cursor: pointer;
    color: white;
  }

  .tit-pil-re {
    user-select: none;
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    font-size: 3rem;
    cursor: pointer;
    justify-content: right;
    text-align: right;
    color: white;
  }

  /* purple pil config*/
  .pil-ev-neuro {
    padding: 1%;
    margin-top: 5vh;
    margin-right: 10rem;
    margin-left: 50px;
    transition: 0.3s;
    background-color: #b376f1;
    border-radius: 138px;
    cursor: pointer;
  }

  .pil-ev-neuro:hover {
    padding: 1%;
    margin-top: 5vh;
    margin-right: 10rem;
    margin-left: 50px;
    transition: 0.3s;
    background-color: #8557b3;
    border-radius: 138px;
    cursor: pointer;
  }

  /* config pil azul*/
  .pil-re-neuro {
    padding: 2%;
    margin-top: 5vh;
    margin-left: 10rem;
    margin-right: 50px;
    transition: 0.3s;
    background-color: #3d9cf3;
    border-radius: 138px;
    cursor: pointer;
  }

  .pil-re-neuro:hover {
    padding: 2%;
    margin-top: 5vh;
    margin-left: 10rem;
    margin-right: 50px;
    transition: 0.3s;
    background-color: #2d72b3;
    border-radius: 138px;
    cursor: pointer;
  }

  .swal-text {
    font-family: "Roboto", sans-serif;
    font-size: 1rem;
    text-align: justify;
    color: white;
    font-weight: bold;
    padding: 2%;
  }
}

@media (max-width: 1280px) {
  .tit-pil {
    user-select: none;
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    font-size: 2.5rem;
    cursor: pointer;
    color: white;
  }

  .tit-pil-re {
    user-select: none;
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    font-size: 2.5rem;
    cursor: pointer;
    justify-content: right;
    text-align: right;
    color: white;
  }

  /* purple pil config*/
  .pil-ev-neuro {
    padding: 1%;
    margin-top: 5vh;
    margin-right: 10rem;
    margin-left: 50px;
    transition: 0.3s;
    background-color: #b376f1;
    border-radius: 138px;
    cursor: pointer;
  }

  .pil-ev-neuro:hover {
    padding: 1%;
    margin-top: 5vh;
    margin-right: 10rem;
    margin-left: 50px;
    transition: 0.3s;
    background-color: #8557b3;
    border-radius: 138px;
    cursor: pointer;
  }

  /* config pil azul*/
  .pil-re-neuro {
    padding: 2%;
    margin-top: 5vh;
    margin-left: 10rem;
    margin-right: 50px;
    transition: 0.3s;
    background-color: #3d9cf3;
    border-radius: 138px;
    cursor: pointer;
  }

  .pil-re-neuro:hover {
    padding: 2%;
    margin-top: 5vh;
    margin-left: 10rem;
    margin-right: 50px;
    transition: 0.3s;
    background-color: #2d72b3;
    border-radius: 138px;
    cursor: pointer;
  }

  .swal-text {
    font-family: "Roboto", sans-serif;
    font-size: 1rem;
    text-align: justify;
    color: white;
    font-weight: bold;
    padding: 2%;
  }
}

@media (max-width: 991px) {
  .div-banner-servicios-mobile {
    display: block;
  }
  .div-banner-servicios{
    display:none;
  }
  .rectangle-background-sv-mobile {
    user-select: none;
    position: absolute;
    z-index: -1;
    height: 96.46302250803859vh;
    width: 100%;
    background: #feeeac;
    background: -moz-linear-gradient(top, #feeeac 0%, #c0e6ce 50%, #86dfee 100%);
    background: -webkit-linear-gradient(
      top,
      #feeeac 0%,
      #c0e6ce 50%,
      #86dfee 100%
    );
    background: linear-gradient(to bottom, #feeeac 0%, #c0e6ce 50%, #86dfee 100%);
    margin-right: 74%;
  }

  .mobile-pil-ev{
    background-color: #b376f1;
    border-radius: 50px;
    margin-left: 10%;
    margin-right: 10%;
  }
  
  .mobile-pil-re{
    background-color: #3d9cf3;
    border-radius: 50px;
    margin-left: 10%;
    margin-right: 10%;
  }

  .tit-serv-mobile{
    font-family: "Roboto", sans-serif;
    font-size: 3.5rem;
    font-weight: bold;
  }

  .tit-ev-mobile{
    font-family: "Roboto", sans-serif;
    font-size: 1.5rem;
    font-weight: bold;
    color: white;
    padding: 1rem;
  }

  .tit-re-mobile{
    font-family: "Roboto", sans-serif;
    font-size: 1.5rem;
    font-weight: bold;
    color: white;
    padding: 1rem;
  }
}

