.contact-mobile-holder{
	display:none;
}

.logo-cont {
  user-select: none;
  padding: 10%;
  width: 30rem;
  height: 30rem;
}

.nomb-cont {
  font-family: "Amiri", serif;
  font-weight: bold;
  font-size: 2.5rem;
  margin-left: 3rem;
  color: #505d68;
}

.datos-cont {
  font-family: "Roboto", sans-serif;
  padding-left: 3rem;
  font-size: 1.5rem;
}

.cont-tit {
  user-select: none;
  font-family: "Roboto", sans-serif;
  font-size: 3.5rem;
}

.form-tit {
  user-select: none;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 1rem;
}

.cont-form-col {
  margin-top: 5%;
}

.cont-bk {
  margin-left: 52%;
  margin-top: 22%;
  opacity: 30%;
  z-index: -1;
  position: absolute;
  width: 900px;
  height: 400px;
  object-fit: cover;
  object-position: -20% 0;
  user-select: none;
}

/* style form */

@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

*,
*:after,
*:before {
  box-sizing: border-box;
}

input {
  user-select: none;
  appearance: none;
  border-radius: 0;
}

.card {
  user-select: none;
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  width: 100rem;
  max-width: 38vw;
  background-color: #ade0ff;
  border-radius: 10px;
  box-shadow: 0 10px 20px 0 rgba(#999, 0.25);
  padding: 0.75rem;
}

.card-form {
  user-select: none;
  padding: 2rem 1rem 0;
}

.input {
  user-select: none;
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  padding-top: 1.5rem;
  & + .input {
    margin-top: 1.5rem;
  }
}

.input-label {
  user-select: none;
  color: #000000;
  font-weight: bold;
  position: absolute;
  top: 1.5rem;
  transition: 0.25s ease;
}

.input-field {
  user-select: none;
  border: 0;
  z-index: 1;
  background-color: transparent;
  border-bottom: 2px solid #eee;
  font: inherit;
  font-size: 1.125rem;
  padding: 0.25rem 0;
  &:focus,
  &:valid {
    outline: 0;
    border-bottom-color: #6658d3;
    & + .input-label {
      color: #6658d3;
      transform: translateY(-1.5rem);
    }
  }
}

.action {
  margin-top: 2rem;
}

.action-button {
  user-select: none;
  cursor: pointer;
  font: inherit;
  font-size: 1.25rem;
  padding: 1em;
  width: 100%;
  font-weight: 500;
  background-color: #6658d3;
  border-radius: 6px;
  color: #fff;
  border: 0;
  &:focus {
    outline: 0;
  }
}

@media (max-width: 1440px) {
  .cont-bk {
    margin-left: 47%;
    margin-top: 37%;
    opacity: 30%;
    z-index: -1;
    position: absolute;
    width: 700px;
    height: 400px;
    object-fit: cover;
    object-position: -20% 0;
  }
}

@media (max-width: 1366px) {
  .cont-bk {
    margin-left: 47%;
    margin-top: 37%;
    opacity: 30%;
    z-index: -1;
    position: absolute;
    width: 700px;
    height: 400px;
    object-fit: cover;
    object-position: -20% 0;
  }
}

@media (max-width: 1280px) {
  .cont-bk {
    margin-left: 55%;
    margin-top: 50%;
    opacity: 30%;
    z-index: -1;
    position: absolute;
    width: 500px;
    height: 400px;
    object-fit: cover;
    object-position: -20% 0;
  }
}

@media (max-width: 1024px) {
  .cont-bk {
    margin-left: 48%;
    margin-top: 60%;
    opacity: 30%;
    z-index: -1;
    position: absolute;
    width: 500px;
    height: 400px;
    object-fit: cover;
    object-position: -20% 0;
  }
}

@media (max-width: 991px) {
  .cont-bk {
    display: none;
  }

  .logo-cont {
    padding: 3rem;
    width: 80%;
  }

  .contact-holder {
    display: none;
  }

  .card-mobile {
    margin: 2rem auto;
    display: flex;
    flex-direction: column;
    width: 100rem;
    max-width: 80vw;
    background-color: #ade0ff;
    border-radius: 10px;
    box-shadow: 0 10px 20px 0 rgba(#999, 0.25);
    padding: 0.75rem;
  }

  .contact-mobile-tit {
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    font-size: 3rem;
  }

  .nomb-cont-mobile {
    font-family: "Amiri", serif;
    font-weight: bold;
    font-size: 2rem;
    color: #505d68;
  }

  .datos-col-mobile {
    margin: 2rem;
  }

  .datos-cont-mobile {
    font-family: "Roboto", sans-serif;
    font-size: 1.4rem;
  }

  .contact-mobile-holder{
	display:block;
  }

  .logo-cont-mobile{
	width: 50vw;
  }

}
