.footer-holder{
    background-color: #5B4373;
    width: 100%;
    height: auto;
}

.link-foot{
    color: white;
    font-family: "Roboto Mono", sans-serif;
    font-weight: bold;
    font-size: 1.4rem;
    
}

.row-link-foot{
    padding-top: 2rem;
    padding-left: 3rem;
}


.logo-foot{
    margin-right: 4rem;
    width: 300px;
    width: 300px;
}

.copy-foot{
    color: white;
    font-family: "Roboto Mono", sans-serif;
    font-weight: bold;
}

@media (max-width: 991px) {
    .logo-foot{
        display: none;
    }

    .link-foot{
        color: white;
        font-family: "Roboto Mono", sans-serif;
        font-weight: bold;
        font-size: 1rem;
        
    }
    
    .row-link-foot{
        padding-top: 2rem;
        padding-left: 3rem;
    }

    .copy-foot{
        margin-top: 2rem;
        color: white;
        font-family: "Roboto Mono", sans-serif;
        font-weight: bold;
        font-size: 0.7rem;
    }
}