.logo-Banner {
  user-select: none;
  padding: 10%;
  height: auto;
}

.row-logo {
  user-select: none;
  height: 100vh;
}

.titulo-header {
  user-select: none;
  align-self: center;
  margin-left: 10%;
  padding-top: 20%;
  font-family: "Amiri", serif;
  font-weight: bold;
  font-size: 5.5rem;
}

.subtitulo-header {
  font-family: "Roboto", serif;
  align-items: left;
  font-weight: bold;
  margin-left: 10%;
  font-size: 3rem;
  user-select: none;
}

.circle-background {
  user-select: none;
  position: absolute;
  z-index: -1;
  height: 96.46302250803859vh;
  width: 46.875vw;
  background: #fff8d8;
  background: -moz-linear-gradient(top, #fff8d8 0%, #fee6e9 50%, #ffd4fb 100%);
  background: -webkit-linear-gradient(
    top,
    #fff8d8 0%,
    #fee6e9 50%,
    #ffd4fb 100%
  );
  background: linear-gradient(to bottom, #fff8d8 0%, #fee6e9 50%, #ffd4fb 100%);
  border-radius: 50%;
  margin-top: 1%;
  margin-left: 50%;
}

.rectangle-background {
  user-select: none;
  position: absolute;
  z-index: -1;
  height: 96.46302250803859vh;
  width: 25.625vw;
  background: #fff8d8;
  background: -moz-linear-gradient(top, #fff8d8 0%, #fee6e9 50%, #ffd4fb 100%);
  background: -webkit-linear-gradient(
    top,
    #fff8d8 0%,
    #fee6e9 50%,
    #ffd4fb 100%
  );
  background: linear-gradient(to bottom, #fff8d8 0%, #fee6e9 50%, #ffd4fb 100%);
  margin-top: 1%;
  margin-left: 74%;
}

.boton-contacto {
  margin-top: 2rem;
  margin-left: 40%;
  font-size: 3rem;
  border: none;
  cursor: pointer;
  font-family: "Roboto", serif;
  color: white;
  border-radius: 50px;
  padding: 1rem;
  background-color: #1169ba;
  transition: 0.3s;
  -webkit-box-shadow: 0px 2px 10px 0px #000000;
  box-shadow: 0px 2px 10px 0px #000000;
}

.boton-contacto:hover {
  -webkit-box-shadow: 0px 2px 5px 0px #000000;
  box-shadow: 0px 2px 5px 0px #000000;
  transition: 0.3s;
  margin-top: 2rem;
  margin-left: 40%;
  font-size: 3rem;
  border: none;
  cursor: pointer;
  font-family: "Roboto", serif;
  color: white;
  border-radius: 50px;
  padding: 1rem;
  background-color: #0c4d8a;
}

/* modal style */

@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

*,
*:after,
*:before {
  box-sizing: border-box;
}

input {
  appearance: none;
  border-radius: 0;
}

.card {
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  width: 100rem;
  max-width: 525px;
  background-color: #ade0ff;
  border-radius: 10px;
  box-shadow: 0 10px 20px 0 rgba(#999, 0.25);
  padding: 0.75rem;
}

.card-form {
  padding: 2rem 1rem 0;
}

.input {
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  padding-top: 1.5rem;
  & + .input {
    margin-top: 1.5rem;
  }
}

.input-label {
  color: #000000;
  font-weight: bold;
  position: absolute;
  top: 1.5rem;
  transition: 0.25s ease;
}

.input-field {
  border: 0;
  z-index: 1;
  background-color: transparent;
  border-bottom: 2px solid #eee;
  font: inherit;
  font-size: 1.125rem;
  padding: 0.25rem 0;
  &:focus,
  &:valid {
    outline: 0;
    border-bottom-color: #6658d3;
    & + .input-label {
      color: #6658d3;
      transform: translateY(-1.5rem);
    }
  }
}

.action {
  margin-top: 2rem;
}

.action-button {
  font: inherit;
  font-size: 1.25rem;
  padding: 1em;
  width: 100%;
  font-weight: 500;
  background-color: #6658d3;
  border-radius: 6px;
  color: #fff;
  border: 0;
  &:focus {
    outline: 0;
  }
}

@media (max-width: 1366px) {
  .titulo-header {
    user-select: none;
    align-self: center;
    margin-left: 15%;
    padding-top: 20%;
    font-family: "Amiri", serif;
    font-weight: bold;
    font-size: 4.5rem;
  }

  .subtitulo-header {
    font-family: "Roboto", serif;
    align-items: left;
    font-weight: bold;
    margin-left: 15%;
    font-size: 2rem;
    user-select: none;
  }
}

@media (max-width: 1440px) {
  .titulo-header {
    user-select: none;
    align-self: center;
    margin-left: 15%;
    padding-top: 20%;
    font-family: "Amiri", serif;
    font-weight: bold;
    font-size: 4.5rem;
  }

  .subtitulo-header {
    font-family: "Roboto", serif;
    align-items: left;
    font-weight: bold;
    margin-left: 15%;
    font-size: 2rem;
    user-select: none;
  }
}

@media (max-width: 1024px) {
  .titulo-header {
    user-select: none;
    align-self: center;
    margin-left: 15%;
    padding-top: 20%;
    font-family: "Amiri", serif;
    font-weight: bold;
    font-size: 3.5rem;
  }

  .subtitulo-header {
    font-family: "Roboto", serif;
    align-items: left;
    font-weight: bold;
    margin-left: 15%;
    font-size: 1.5rem;
    user-select: none;
  }
}

@media (max-width: 1366px) {
  .titulo-header {
    user-select: none;
    align-self: center;
    margin-left: 15%;
    padding-top: 20%;
    font-family: "Amiri", serif;
    font-weight: bold;
    font-size: 4.5rem;
  }

  .subtitulo-header {
    font-family: "Roboto", serif;
    align-items: left;
    font-weight: bold;
    margin-left: 15%;
    font-size: 2rem;
    user-select: none;
  }
}

@media (max-width: 991px) {
  .rectangle-background-mobile {
    user-select: none;
    position: absolute;
    z-index: -2;
    height: 96.46302250803859vh;
    width: 100%;
    background: #fff8d8;
    background: -moz-linear-gradient(
      top,
      #fff8d8 0%,
      #fee6e9 50%,
      #ffd4fb 100%
    );
    background: -webkit-linear-gradient(
      top,
      #fff8d8 0%,
      #fee6e9 50%,
      #ffd4fb 100%
    );
    background: linear-gradient(
      to bottom,
      #fff8d8 0%,
      #fee6e9 50%,
      #ffd4fb 100%
    );
    margin-top: 1%;
  }

  .titulo-header-mobile {
    user-select: none;
    align-self: center;
    margin-left: 10%;
    padding-top: 10%;
    font-family: "Amiri", serif;
    font-weight: bold;
    font-size: 4rem;
  }
  
  .subtitulo-header-mobile {
    font-family: "Roboto", serif;
    align-items: left;
    font-weight: bold;
    margin-left: 10%;
    font-size: 2rem;
    user-select: none;
  }

  .boton-contacto-mobile {
    margin-top: 2rem;
    margin-left: 6rem;
    align-items: center;
    font-size: 3rem;
    border: none;
    cursor: pointer;
    font-family: "Roboto", serif;
    color: white;
    border-radius: 50px;
    padding: 1rem;
    background-color: #1169ba;
    transition: 0.3s;
    -webkit-box-shadow: 0px 2px 10px 0px #000000;
    box-shadow: 0px 2px 10px 0px #000000;
  }
}
