.page-spacer {
  height: 120px;
  width: auto;
}

body {
  background-color: #fdfcfa;
}

html {
  scroll-behavior: smooth;
}

.icon-menu {
  color: #fdfcfa;
  font-size: 20px;
  font-weight: bold;
}
