.ambitos-holder-mobile{
  display: none;
}

.am-tit {
  font-family: "Roboto", sans-serif;
  font-size: 3.5rem;
  font-weight: bold;
  color: #1e1e1e;
  margin-top: 70px;
  margin-bottom: 50px;
  user-select: none;
}

.aa-holder {
  padding-left: 40px;
  padding-right: 40px;
  background-color: #55315c;
  border-radius: 45px;
  border: 0px;
  color: white;
  transition: 0.3s;
  user-select: none;
  cursor: pointer;
}


.aa-holder:hover {
  padding-left: 40px;
  padding-right: 40px;
  background-color: #8f529c;
  border-radius: 45px;
  border: 0px;
  color: white;
  transition: 0.3s;
  cursor: pointer;
}

.bk-brain {
  z-index: -1;
  user-select: none;
  display: block;
  padding-right: 110px;
  width: 30rem;
}

.first-row {
  margin-bottom: 3rem;
}

.last-row {
  margin-top: 3rem;
}

/*Trastornos Neurodegenerativos*/
.icon {
  width: 90px;
  height: 90px;
  padding: 10px;
}

.tit-tr-nd {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
}

.content-sty {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 1rem;
}

/* balls background*/

.ball-1 {
  margin-left: 33%;
  margin-top: 22%;
  background-color: #ade0ff;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  z-index: -5;
  position: absolute;
  justify-content: right;
}

.ball-2 {
  margin-left: 47%;
  margin-top: 12%;
  background-color: #ade0ff;
  width: 150px;
  height: 150px;
  border-radius: 100px;
  z-index: -5;
  position: absolute;
  justify-content: right;
}

.ball-3 {
  margin-left: 53%;
  margin-top: 30%;
  background-color: #ade0ff;
  width: 200px;
  height: 200px;
  border-radius: 100px;
  z-index: -5;
  position: absolute;
  justify-content: right;
}

.ball-4 {
  margin-left: 63%;
  margin-top: 15%;
  background-color: #ade0ff;
  width: 300px;
  height: 300px;
  border-radius: 200px;
  z-index: -5;
  position: absolute;
  justify-content: right;
}

.ball-5 {
  margin-left: 73%;
  margin-top: 30%;
  background-color: #ade0ff;
  width: 500px;
  height: 500px;
  border-radius: 250px;
  z-index: -5;
  position: absolute;
}

@media (max-width: 1440px) {
  .ball-1 {
    margin-left: 33%;
    margin-top: 38%;
    background-color: #ade0ff;
    width: 20px;
    height: 20px;
    border-radius: 50px;
    z-index: -5;
    position: absolute;
    justify-content: right;
  }

  .ball-2 {
    margin-left: 47%;
    margin-top: 25%;
    background-color: #ade0ff;
    width: 60px;
    height: 60px;
    border-radius: 100px;
    z-index: -5;
    position: absolute;
    justify-content: right;
  }

  .ball-3 {
    margin-left: 53%;
    margin-top: 50%;
    background-color: #ade0ff;
    width: 100px;
    height: 100px;
    border-radius: 100px;
    z-index: -5;
    position: absolute;
    justify-content: right;
  }

  .ball-4 {
    margin-left: 65%;
    margin-top: 30%;
    background-color: #ade0ff;
    width: 190px;
    height: 190px;
    border-radius: 200px;
    z-index: -5;
    position: absolute;
    justify-content: right;
  }

  .ball-5 {
    margin-left: 70%;
    margin-top: 50%;
    background-color: #ade0ff;
    width: 290px;
    height: 290px;
    border-radius: 250px;
    z-index: -5;
    position: absolute;
  }

  .bk-brain {
    z-index: -1;
    user-select: none;
    display: block;
    padding-right: 110px;
    width: 20rem;
  }

  .tit-tr-nd {
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    font-size: 1rem;
  }

  .content-sty {
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    font-size: 0.9rem;
  }
}

@media (max-width: 1366px) {
  .ball-1 {
    margin-left: 33%;
    margin-top: 38%;
    background-color: #ade0ff;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    z-index: -5;
    position: absolute;
    justify-content: right;
  }

  .ball-2 {
    margin-left: 47%;
    margin-top: 25%;
    background-color: #ade0ff;
    width: 100px;
    height: 100px;
    border-radius: 100px;
    z-index: -5;
    position: absolute;
    justify-content: right;
  }

  .ball-3 {
    margin-left: 53%;
    margin-top: 50%;
    background-color: #ade0ff;
    width: 150px;
    height: 150px;
    border-radius: 100px;
    z-index: -5;
    position: absolute;
    justify-content: right;
  }

  .ball-4 {
    margin-left: 65%;
    margin-top: 30%;
    background-color: #ade0ff;
    width: 250px;
    height: 250px;
    border-radius: 200px;
    z-index: -5;
    position: absolute;
    justify-content: right;
  }

  .ball-5 {
    margin-left: 70%;
    margin-top: 50%;
    background-color: #ade0ff;
    width: 400px;
    height: 400px;
    border-radius: 250px;
    z-index: -5;
    position: absolute;
  }
}

@media (max-width: 1024px) {
  .ball-1 {
    margin-left: 23%;
    margin-top: 38%;
    background-color: #ade0ff;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    z-index: -5;
    position: absolute;
    justify-content: right;
  }

  .ball-2 {
    margin-left: 37%;
    margin-top: 25%;
    background-color: #ade0ff;
    width: 100px;
    height: 100px;
    border-radius: 100px;
    z-index: -5;
    position: absolute;
    justify-content: right;
  }

  .ball-3 {
    margin-left: 43%;
    margin-top: 50%;
    background-color: #ade0ff;
    width: 150px;
    height: 150px;
    border-radius: 100px;
    z-index: -5;
    position: absolute;
    justify-content: right;
  }

  .ball-4 {
    margin-left: 55%;
    margin-top: 30%;
    background-color: #ade0ff;
    width: 250px;
    height: 250px;
    border-radius: 200px;
    z-index: -5;
    position: absolute;
    justify-content: right;
  }

  .ball-5 {
    margin-left: 60%;
    margin-top: 50%;
    background-color: #ade0ff;
    width: 400px;
    height: 400px;
    border-radius: 250px;
    z-index: -5;
    position: absolute;
  }
}

@media (max-width: 991px) {
  .ambitos-holder-mobile{
    display: block;
    background-color: #ade0ff68;
    padding-bottom: 5rem;
    margin-top:4rem;
  }
  .ball-1 {
    display: none;
  }

  .ball-2 {
    display: none;
  }

  .ball-3 {
    display: none;
  }

  .ball-4 {
    display: none;
  }

  .ball-5 {
    display: none;
  }

  .AmbitosHolder{
    display: none;
  }

  .aa-holder {
    padding-left: 40px;
    padding-right: 40px;
    width: 90vw;
    background-color: #55315c;
    border-radius: 45px;
    border: 0px;
    color: white;
    transition: 0.3s;
    user-select: none;
    cursor: pointer;
  }
}